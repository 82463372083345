export default class TasksIndex {

  constructor() {
      this.bindEvents();
  }

  bindEvents() {
      let self = this;

      $(".btn-assign").off("click").click(function(e) {
        self.updateTask(this);
      });

      $(".btn-close").off("click").click(function(e) {
        self.updateTask(this);
      });

       $(".btn-saw").off("click").click(function(e) {
        self.updateTask(this);
      });

       $(".btn-reject").off("click").click(function(e) {
        self.updateTask(this);
      });

      $(".open-content-modal").off("click").on("click", function(e) {
        $("#modal-task-content").modal();
        $("#modal-task-content .modal-title").html($(this).data("title"));
        $("#modal-task-content .modal-body").html($(this).data("content").split("\n").join("<br/>"));
      });
  }

  updateTask(button, callback) {

    var ajaxData = {
      url: $(button).data("href"),
      method: "PUT"
    };

    $.ajax(ajaxData).done(function(json) {
      var notification = json.business_notification;
      if(notification.business_notification_type=="callback_request") {
        if(notification.notification_status=="assigned") {
          $(button).addClass("d-none");
          $("#call_me_back_item_"+$(button).data("itemId")).find(".btn-close").removeClass("d-none");
          $("#call_me_back_item_"+$(button).data("itemId")).appendTo("#call-me-back-mine-tbody");
          $("#call-me-back-mine-no-task").addClass("d-none");
          $("#call-me-back-mine-count").html("("+$("#call-me-back-mine-tbody").children().length+")");
          $("#call-me-back-not_assigned-count").html("("+$("#call-me-back-not_assigned-tbody").children().length+")");
        }
        else {
         $("#call_me_back_item_"+$(button).data("itemId")).remove(); 
        }


        $("#call-me-back-mine-count").html("("+$("#call-me-back-mine-tbody").children().length+")");
        $("#call-me-back-not_assigned-count").html("("+$("#call-me-back-not_assigned-tbody").children().length+")");
        if($("#call-me-back-not_assigned-tbody").children().length==0) {
          $("#call-me-back-not_assigned-no-task").removeClass("d-none");
        }
        else {
          $("#call-me-back-not_assigned-no-task").addClass("d-none");
        }

        if($("#call-me-back-mine-tbody").children().length==0) {
          $("#call-me-back-mine-no-task").removeClass("d-none");
        }
        else {
          $("#call-me-back-mine-no-task").addClass("d-none");
        }

        $("#call_me_back_total_count").html("("+($("#call-me-back-mine-tbody").children().length+$("#call-me-back-not_assigned-tbody").children().length)+")")
      }
      else if(notification.business_notification_type=="monitor_callback_request") {
        if(notification.notification_status=="assigned") {
          $(button).addClass("d-none");
          $("#monitor_call_me_back_item_"+$(button).data("itemId")).find(".btn-close").removeClass("d-none");
          $("#monitor_call_me_back_item_"+$(button).data("itemId")).appendTo("#monitors-call-me-back-mine-tbody");
          $("#monitors-call-me-back-mine-no-task").addClass("d-none");
          $("#monitors-call-me-back-mine-count").html("("+$("#monitors-call-me-back-mine-tbody").children().length+")");
          $("#monitors-call-me-back-not_assigned-count").html("("+$("#monitors-call-me-back-not_assigned-tbody").children().length+")");
        }
        else {
         $("#monitor_call_me_back_item_"+$(button).data("itemId")).remove(); 
        }


        $("#monitors-call-me-back-mine-count").html("("+$("#monitors-call-me-back-mine-tbody").children().length+")");
        $("#monitors-call-me-back-not_assigned-count").html("("+$("#monitors-call-me-back-not_assigned-tbody").children().length+")");
        if($("#monitors-call-me-back-not_assigned-tbody").children().length==0) {
          $("#monitors-call-me-back-not_assigned-no-task").removeClass("d-none");
        }
        else {
          $("#monitors-call-me-back-not_assigned-no-task").addClass("d-none");
        }

        if($("#monitors-call-me-back-mine-tbody").children().length==0) {
          $("#monitors-call-me-back-mine-no-task").removeClass("d-none");
        }
        else {
          $("#monitors-call-me-back-mine-no-task").addClass("d-none");
        }

        $("#monitors-call_me_back_total_count").html("("+($("#monitors-call-me-back-mine-tbody").children().length+$("#monitors-call-me-back-not_assigned-tbody").children().length)+")")
      }
      else if(notification.business_notification_type=="business_alert") {
        if(notification.notification_status=="assigned") {
          $(button).addClass("d-none");
          $("#business_alert_item_"+$(button).data("itemId")).find(".btn-close").removeClass("d-none");
          $("#business_alert_item_"+$(button).data("itemId")).appendTo("#business-alerts-mine-tbody");
          $("#business-alerts-mine-no-task").addClass("d-none");
          $("#business-alerts-mine-count").html("("+$("#business-alerts-mine-tbody").children().length+")");
          $("#business-alerts-not_assigned-count").html("("+$("#business-alerts-not_assigned-tbody").children().length+")");
        }
        else {
         $("#business_alert_item_"+$(button).data("itemId")).remove(); 
        }


        $("#business-alerts-mine-count").html("("+$("#business-alerts-mine-tbody").children().length+")");
        $("#business-alerts-not_assigned-count").html("("+$("#business-alerts-not_assigned-tbody").children().length+")");
        if($("#business-alerts-not_assigned-tbody").children().length==0) {
          $("#business-alerts-not_assigned-no-task").removeClass("d-none");
        }
        else {
          $("#business-alerts-not_assigned-no-task").addClass("d-none");
        }

        if($("#business-alerts-mine-tbody").children().length==0) {
          $("#business-alerts-mine-no-task").removeClass("d-none");
        }
        else {
          $("#business-alerts-mine-no-task").addClass("d-none");
        }

        $("#business_alert_total_count").html("("+($("#business-alerts-mine-tbody").children().length+$("#business-alerts-not_assigned-tbody").children().length)+")")
      }
      else if(notification.business_notification_type=="cpf_action") {
        console.log("cpf_action");
        console.log("notification.notification_status==assigned");
        console.log(notification.notification_status=="assigned");
        if(notification.notification_status=="assigned") {
          $(button).addClass("d-none");
          $("#cpf_action_item_"+$(button).data("itemId")).find(".btn-close").removeClass("d-none");
          $("#cpf_action_item_"+$(button).data("itemId")).appendTo("#cpf-actions-mine-tbody");
          $("#cpf-actions-mine-no-task").addClass("d-none");
          $("#cpf-actions-mine-count").html("("+$("#cpf-actions-mine-tbody").children().length+")");
          $("#cpf-actions-not_assigned-count").html("("+$("#cpf-actions-not_assigned-tbody").children().length+")");
        }
        else {
         $("#cpf_action_item_"+$(button).data("itemId")).remove();
        }


        $("#cpf-actions-mine-count").html("("+$("#cpf-actions-mine-tbody").children().length+")");
        $("#cpf-actions-not_assigned-count").html("("+$("#cpf-actions-not_assigned-tbody").children().length+")");
        if($("#cpf-actions-not_assigned-tbody").children().length==0) {
          $("#cpf-actions-not_assigned-no-task").removeClass("d-none");
        }
        else {
          $("#cpf-actions-not_assigned-no-task").addClass("d-none");
        }

        if($("#cpf-actions-mine-tbody").children().length==0) {
          $("#cpf-actions-mine-no-task").removeClass("d-none");
        }
        else {
          $("#cpf-actions-mine-no-task").addClass("d-none");
        }

        $("#cpf_action_total_count").html("("+($("#cpf-actions-mine-tbody").children().length+$("#cpf-actions-not_assigned-tbody").children().length)+")")
      }
      else if(notification.business_notification_type=="client_action" && 
        [ "client_action_record_payment_successful", 
          "client_action_additional_hour_payment_successful", 
          "client_action_online_code", 
          "client_action_record_waiting_confirmation"].indexOf(notification.alert_type)!=-1) {
        if(notification.notification_status=="done") {
          $("#customer_payment_item_"+$(button).data("itemId")).remove(); 
        }

        $("#customer-payments-not_assigned-count").html("("+$("#customer-payments-not_assigned-tbody").children().length+")");
        if($("#customer-payments-not_assigned-tbody").children().length==0) {
          $("#customer-payments-not_assigned-no-task").removeClass("d-none");
        }
        else {
          $("#customer-payments-not_assigned-no-task").addClass("d-none");
        }

        $("#client_payments_total_count").html("("+($("#customer-payments-not_assigned-tbody").children().length)+")")
      }
      else if(notification.business_notification_type=="client_action") {
        if(notification.notification_status=="done") {
          $("#customer_action_item_"+$(button).data("itemId")).remove(); 
        }

        $("#customer-actions-not_assigned-count").html("("+$("#customer-actions-not_assigned-tbody").children().length+")");
        if($("#customer-actions-not_assigned-tbody").children().length==0) {
          $("#customer-actions-not_assigned-no-task").removeClass("d-none");
        }
        else {
          $("#customer-actions-not_assigned-no-task").addClass("d-none");
        }

        $("#customer_action_total_count").html("("+($("#customer-actions-not_assigned-tbody").children().length)+")")
      }
      else if(notification.business_notification_type=="partner_callback_request") {
        if(notification.notification_status=="assigned") {
          $(button).addClass("d-none");
          $("#partner_call_me_back_item_"+$(button).data("itemId")).find(".btn-close").removeClass("d-none");
          $("#partner_call_me_back_item_"+$(button).data("itemId")).appendTo("#partners-call-me-back-mine-tbody");
          $("#partners-call-me-back-mine-no-task").addClass("d-none");
          $("#partners-call-me-back-mine-count").html("("+$("#partners-call-me-back-mine-tbody").children().length+")");
          $("#partners-call-me-back-not_assigned-count").html("("+$("#partners-call-me-back-not_assigned-tbody").children().length+")");
        }
        else {
         $("#partner_call_me_back_item_"+$(button).data("itemId")).remove(); 
        }


        $("#partners-call-me-back-mine-count").html("("+$("#partners-call-me-back-mine-tbody").children().length+")");
        $("#partners-call-me-back-not_assigned-count").html("("+$("#partners-call-me-back-not_assigned-tbody").children().length+")");
        if($("#partners-call-me-back-not_assigned-tbody").children().length==0) {
          $("#partners-call-me-back-not_assigned-no-task").removeClass("d-none");
        }
        else {
          $("#partners-call-me-back-not_assigned-no-task").addClass("d-none");
        }

        if($("#partners-call-me-back-mine-tbody").children().length==0) {
          $("#partners-call-me-back-mine-no-task").removeClass("d-none");
        }
        else {
          $("#partners-call-me-back-mine-no-task").addClass("d-none");
        }

        $("#partner_call_me_back_total_count").html("("+($("#partners-call-me-back-mine-tbody").children().length+$("#partners-call-me-back-not_assigned-tbody").children().length)+")")
      }
      else if(notification.business_notification_type=="partner_alert") {
        if(notification.notification_status=="assigned") {
          $(button).addClass("d-none");
          $("#partner_action_item_"+$(button).data("itemId")).find(".btn-close").removeClass("d-none");
          $("#partner_action_item_"+$(button).data("itemId")).appendTo("#partners-actions-mine-tbody");
          $("#partner-actions-mine-no-task").addClass("d-none");
          $("#partners-actions-mine-count").html("("+$("#partners-call-me-back-mine-tbody").children().length+")");
          $("#partners-actions-not_assigned-count").html("("+$("#partners-actions-not_assigned-tbody").children().length+")");
        }
        else {
         $("#partner_action_item_"+$(button).data("itemId")).remove(); 
        }


        $("#partner_actions-mine-count").html("("+$("#partners-actions-mine-tbody").children().length+")");
        $("#partner_actions-not_assigned-count").html("("+$("#partners-actions-not_assigned-tbody").children().length+")");
        if($("#partner-actions-not_assigned-tbody").children().length==0) {
          $("#partner-actions-not_assigned-no-task").removeClass("d-none");
        }
        else {
          $("#partner-actions-not_assigned-no-task").addClass("d-none");
        }

        if($("partner-actions-mine-tbody").children().length==0) {
          $("#partner-actions-mine-no-task").removeClass("d-none");
        }
        else {
          $("#partner-actions-mine-no-task").addClass("d-none");
        }

        $("#partner-actions_total_count").html("("+($("#partner-actions-mine-tbody").children().length+$("#partner-actions-not_assigned-tbody").children().length)+")")
      }
    });
  }

}